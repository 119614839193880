#features-slider {

}

.slider-page {

}

.slider-tabs-contaner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-tabs-contaner2 {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}
@media(max-width: 800px) {
  .slider-tabs-contaner2 {
    flex-direction: column;
  }
}

.slider-tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.slider-tabs h3 {
  color: rgb(231, 222, 207);
  padding: 10px 20px;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.slider-tabs .tab-active {
  color: inherit;
  background: var(--block);
}

.slider-tabs h3::after {
  content: '';
  display: block;
  border-bottom: 1px solid var(--second);
  width: 0%;
  position: relative;
  top: 10px;
}

.slider-tabs .tab-active::after {
  width: 100%;
  transition: width 8s linear;
}


.slider-text {
  display: flex;
  justify-content: center;
  padding: 30px;
  padding-bottom: 0;
  line-height: 1.9rem;
  background: var(--block);
  border-radius: 10px 10px 0 0;
}

.slider-text div {
  position: absolute;
  opacity: 0;
  overflow: hidden;
}

.slider-text p {
}

.slider-text .text-active {
  position: static;
  opacity: 1;
  transition: all 1s linear;
}

.slider-image {
  display: flex;
  justify-content: center;
  background: var(--block);
  border-radius: 0 0 10px 10px;
}

.slider-image div {
  position: absolute;
  opacity: 0;
}

.slider-image div.image-active {
  position: static;
  opacity: 1;
  transition: all 1s linear;
}

